import { AuthenticationProviderType } from '@/domain/enums/authentication-provider-type';
import { PopUpType } from '@/domain/enums/pop-up-type';
import globalStore from '@/store/global';
import globals from '@/utils/globals';
import { SimpleModal, ComplexModal, ModalState } from '@studyportals/modal';
import { IModalConfig } from '@studyportals/modal';
import { ref, Ref, computed, reactive } from 'vue';
import { IncentiveBase } from '../incentive-base.class';

export default class Popup extends IncentiveBase {
	public popupContent: Ref<HTMLInputElement | null> = ref(null);
	public referrer: string;
	public identifier: string;
	public type: string;
	public state = reactive({ hidden: true });
	public modal: SimpleModal | ComplexModal | null = null;
	private appIdentifier: string;

	public isPending = computed((): boolean => {
		return (
			globalStore.getters.isPending(AuthenticationProviderType.GOOGLE) ||
			globalStore.getters.isPending(AuthenticationProviderType.FACEBOOK) ||
			globalStore.getters.isPending(AuthenticationProviderType.LINKEDIN) ||
			globalStore.getters.isPending(AuthenticationProviderType.DIRECT)
		);
	});

	constructor(referrer = '', identifier = 'AuthPopUp', type = 'Simple', appIdentifier = '') {
		super();

		this.referrer = referrer;
		this.identifier = identifier;
		this.type = type;
		this.appIdentifier = appIdentifier;
	}

	created(): void {
		globals.eventBus.on('openPopup', (params: any) => {
			if (this.isAuthPopupAndInvalidReferrer(params)) {
				return;
			}
			if (this.isInvalidInstance(params)) {
				return;
			}
			this.openPopup();
		});
		globals.eventBus.on('closePopup', (identifier) => {
			if (identifier !== this.identifier) {
				return;
			}
			this.closePopup();
		});
	}

	mounted(): void {
		document.addEventListener('RenderAuthenticationService', (event: any) => {
			if (event.detail.referrer === this.referrer) {
				this.openPopup();
			}
		});
		this.initiatePopup();
	}

	initiatePopup(): void {
		if (this.popupContent.value == null) {
			return;
		}
		const modalConfig: IModalConfig = {
			cssClassName: this.identifier,
			destroyOnClose: true,
			onClose: this.onCloseHandler.bind(this),
		};
		if (this.type === 'Complex') {
			this.modal = new ComplexModal(this.popupContent.value, modalConfig);
			return;
		}
		this.modal = new SimpleModal(this.popupContent.value, modalConfig);
	}

	closePopup(): void {
		this.state.hidden = true;
		if (this.modal == null) {
			return;
		}
		const modalState = this.modal.getState();
		if (modalState === ModalState.OPEN || modalState === ModalState.VISIBLE) {
			(window['ModalManager'] as { close: (modal: SimpleModal | ComplexModal) => void }).close(this.modal);
		}
	}

	openPopup(): void {
		this.state.hidden = false;
		(window['ModalManager'] as { open: (modal: SimpleModal | ComplexModal | null) => void }).open(this.modal);
	}

	onCloseHandler(): void {
		if (this.identifier as PopUpType === PopUpType.OPT_INT_POP_UP ||
			this.identifier as PopUpType=== PopUpType.EXPERIMENT_A_OPT_IN_POP_UP ||
			this.identifier as PopUpType === PopUpType.EXPERIMENT_C_OPT_IN_POP_UP) {
			globalStore.actions.dismissOptIn();
		}
		if (this.identifier as PopUpType === PopUpType.AUTH_POP_UP && !this.isPending.value) {
			const referrer = this.incentiveStore.getters.referrer();
			globalStore.actions.dismissAuthPopup(referrer);
		}
		this.state.hidden = true;
	}

	private isAuthPopupAndInvalidReferrer(params: any): boolean {
		return params.identifier === PopUpType.AUTH_POP_UP && params.referrer !== this.referrer;
	}

	private isInvalidInstance(params: any): boolean {
		return params.identifier !== this.identifier || params.appIdentifier !== this.appIdentifier;
	}
}

declare const STAGE: string;

export class SPTracker {
	private config: object;

	constructor(config) {
		this.config = config;
	}

	public async track(options): Promise<void> {
		options.url = this.getUri();
		options = Object.assign(options, this.config);

		await this.sendRequest(this.getRequestOptions(options));
	}

	private getUri(): string {
		return window.location.toString();
	}

	private sendRequest(requestOptions): Promise<any> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		return fetch(requestOptions.url, {
			method: requestOptions.method,
			body: requestOptions.body,
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((result) => {
			return result.json();
		});
	}

	private getRequestOptions(options): object {
		let url = 'https://sp-analytics.prtl.co/events';

		if (STAGE === 'tst') {
			url = 'https://sp-analytics.tst.prtl.co/events';
		}

		return {
			url,
			method: 'PUT',
			body: JSON.stringify(options),
		};
	}
}

import FormApp from '@/presentation/components/form-app.vue';
import { createApp, h } from 'vue';
import { ModalManager } from '@studyportals/modal';
import {
	LayoutType,
	Referrer,
	RenderIncentiveCommand,
	RenderFormCommand,
	AuthenticationServiceReadyEvent,
} from '../interfaces';
import { AuthenticationServiceApplication } from './application/authentication-service-application';
import { AccountService } from './domain/services/account-service';
import { LogOutService } from './domain/services/log-out-service';
import App from './presentation/components/app.vue';
import GlobalsPlugin from './utils/vue-globals';
import { ActionState } from '../interfaces/enums/action-state';
import { LoginStatusCheckerPlugin } from './store/global/plugins/login-status-checker-plugin';
import { YoloDisplayedEventHandler } from './store/global/plugins/yolo-displayed-event-handler';
import { FacebookEventListenerPlugin } from './store/global/plugins/facebook-event-listener-plugin';
import { ExperimentSystemPlugin } from './store/global/plugins/experiment-system-plugin';
import OptIn from './presentation/components/OptIn/OptIn.vue';
import { PopUpType } from './domain/enums/pop-up-type';
import globals from './utils/globals';
import { createIncentiveStore } from './store/incentive';
import { StudentAuthenticationService } from './domain/services/student-authentication-service';
import { ISessionService } from '@studyportals/student-interfaces';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';

declare global {
	interface Window {
		rollbar: any;
		rollbarIgnore: (...args) => boolean;
		EventAggregationService: IEventAggregationService;
	}
}
class Main {
	private optInPopup;
	private mountedApps = new Map<string, any>();

	constructor() {
		globals.studentAuthenticationService = new StudentAuthenticationService();
	}
	initialize(): void {
		void new LoginStatusCheckerPlugin().initiate();
		void new YoloDisplayedEventHandler().initiate();
		void new FacebookEventListenerPlugin().initiate();
		void new ExperimentSystemPlugin().initiate();

		this.constructModalManager();

		this.optInPopup = createApp({
			render: () => {
				return h(OptIn, {
					appIdentifier: 'OPTIN',
				});
			},
		});

		this.createOptInPopUp();

		document.addEventListener('RenderAuthenticationService', (event: any) => {
			if (typeof event.detail === 'undefined') {
				return;
			}

			this.handleRenderIncentiveCommand(event as RenderIncentiveCommand);
		});

		this.createAuthenticationServiceApplication();
	}

	public constructModalManager(): void {
		if (typeof window['ModalManager'] === 'undefined') {
			window['ModalManager'] = new ModalManager();
		}
	}

	public createOptInPopUp(): void {
		const optInPopupElement = document.createElement('div');
		optInPopupElement.classList.add('OptInPopUp');
		document.body.appendChild(optInPopupElement);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		this.optInPopup.provide('incentiveStore', createIncentiveStore());
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		this.optInPopup.mount(optInPopupElement);
	}

	public createAuthenticationServiceApplication(): void {
		if (!this.areSessionServiceAndEventAggregationServiceAvailable()) {
			return;
		}

		const logoutService = new LogOutService(
			window['SessionService'] as ISessionService,
			window['EventAggregationService'],
		);
		const accountService = new AccountService(window['SessionService'] as ISessionService);
		const application = new AuthenticationServiceApplication(logoutService, accountService);

		window['AuthenticationService'] = application;

		window['EventAggregationService'].publishTo(
			AuthenticationServiceReadyEvent.EventType,
			new AuthenticationServiceReadyEvent(application),
		);
	}

	public areSessionServiceAndEventAggregationServiceAvailable(): boolean {
		return window['SessionService'] !== undefined && window['EventAggregationService'] !== undefined;
	}

	public handleRenderIncentiveCommand(command: RenderIncentiveCommand): void {
		this.renderIncentive(command.detail.referrer, command.detail.tabToShow, command.detail.referrerDirect);
	}

	public handleRenderFormCommand(command: RenderFormCommand): void {
		const node: Element | null = document.querySelector(`.AuthFormPlaceholder[data-form-type=${command.type}]`);

		if (node === null || command.type !== node.getAttribute('data-form-type')) {
			return;
		}

		const app = createApp({
			render: () => {
				return h(FormApp, {
					form: command.type,
				});
			},
		});

		app.use(GlobalsPlugin);
		app.mount(node);
	}

	private renderIncentive(
		referrer: string,
		tabToShow: ActionState = ActionState.THIRD_PARTY_REGISTRATION,
		referrerDirect?: Referrer,
	): void {
		const node: Element | null = document.querySelector(`.AuthPlaceholder[data-referrer=${referrer}]`);

		if (node === null || referrer !== node.getAttribute('data-referrer')) {
			return;
		}

		/* tslint:disable */
		const layout: any = node.getAttribute('data-layout');
		const portalType: any = node.getAttribute('data-portal-type');
		const popup: any = node.getAttribute('data-popup');

		/*	Due to current implementation of popups being toggled by firing the RenderIncentive event, we need to keep
			track of the app instances so we do not create duplicates.
		*/
		const identifier = `${referrer}_${layout}_${tabToShow}`;
		const app = this.mountedApps.get(identifier);

		if (!app) {
			const authenticationService = createApp({
				render: () => {
					return h(App, {
						layout,
						referrer,
						tab_to_show: tabToShow,
						portal_type: portalType,
						// eslint-disable-next-line eqeqeq
						popup: popup == 'true',
						appIdentifier: identifier,
					});
				},
			});

			const incentiveStore = createIncentiveStore();
			incentiveStore.actions.saveReferrer(referrer as Referrer);
			incentiveStore.actions.saveReferrerDirect(referrerDirect);

			authenticationService.provide('incentiveStore', incentiveStore);
			authenticationService.mount(node);
			this.mountedApps.set(identifier, authenticationService);
		} else {
			if (popup === 'true') {
				globals.eventBus.emit('openPopup', {
					identifier: PopUpType.AUTH_POP_UP,
					referrer,
					appIdentifier: identifier,
				});
			}

			if (
				this.isBestfitIncentive(referrer as Referrer, layout as LayoutType) ||
				this.isReviewsIncentive(referrer as Referrer, layout as LayoutType) ||
				this.isScholarshipSearchResultsIncentive(referrer as Referrer, layout as LayoutType)
			) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
				app.unmount();
				this.mountedApps.delete(identifier);
				this.renderIncentive(referrer, tabToShow);
			}
		}
	}

	private isBestfitIncentive(referrer: Referrer, layout: LayoutType): boolean {
		return referrer === Referrer.PROFILE_QUESTIONNAIRES && layout === LayoutType.PROFILE_QUESTIONNAIRES;
	}

	private isReviewsIncentive(referrer: Referrer, layout: LayoutType): boolean {
		return referrer === Referrer.REVIEWS && layout === LayoutType.REVIEWS;
	}

	private isScholarshipSearchResultsIncentive(referrer: Referrer, layout: LayoutType): boolean {
		return referrer === Referrer.SCHOLARSHIP_SEARCH_RESULTS && layout === LayoutType.TWO_COLUMN_INCENTIVE;
	}
}

const main = new Main();
export default main;
main.initialize();

export enum LayoutType {
	MAIN = 'Main',
	MAIN_YOLO = 'MainYolo',
	PROFILE_QUESTIONNAIRES = 'ProfileQuestionnaires',
	EXCLUSIVE_ACCESS = 'ExclusiveAccess',
	HEADER = 'Header',
	REVIEWS = 'Reviews',
	OPENID = 'OpenId',
	ATP_INCENTIVE = 'ATPIncentive',
	COPILOT_INCENTIVE = 'CopilotIncentive',
	TWO_COLUMN_INCENTIVE = 'TwoColumnIncentive',
}
